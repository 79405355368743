import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import mapStyle__dark from "./MapStyle__dark"
import mapStyle__light from "./MapStyle__light"
import "./MapStyle.scss"
import NeuButton from "../../Components/UI/Button/Neu_button"
import TextBox from "../../Components/Textbox/Textbox";
import axios from "axios";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";



import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";

const libraries = ["places"];

const token = localStorage.getItem('token');

const center = { lat: 27.652, lng: 78.526 }

function MapGetLocation(props) {

  console.log(token)

  let options = null, themeData;
  themeData = localStorage.getItem('theme-data');

  const [selectedId, setSelectedId] = useState(props?.ids[0]?.id)

  // alert(localStorage.getItem('theme-data'))
  if (themeData === "Dark") {
    options = {
      styles: mapStyle__dark,
      disableDefaultUI: true,
      zoomControl: true
    }
  }
  else {
    options = {
      styles: mapStyle__light,
      disableDefaultUI: true,
      zoomControl: true
    }
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })

  let lat = props.location ? props.location[0] : "27";
  let lng = props.location ? props.location[1] : "78";
  lat = parseFloat(lat);
  lng = parseFloat(lng);

  console.log(lat)
  console.log(lng)



  const mapContainerStyle = {
    width: "100%", height: "100%",
  }

  const [markers, setMarkers] = React.useState([{
    lat: 27.13135,
    lng: 78.132135,
    time: new Date(),
  },]);



  useEffect(() => {

    let deviceInfo = JSON.parse(localStorage.getItem('devices')) || null;
    if (deviceInfo) {
      console.log({ deviceInfo, selectedId })
      let location = deviceInfo?.devices.find(device => device?.deviceDetails?.device_id === selectedId)?.deviceDetails?.location;
      if (location) {

        setMarkers((prev) => [
          {
            ...prev[0],
            lat: location[0], // or handle it differently based on your use case
            lng: location[1], // or handle it differently based on your use case
          },
        ]);
      }
      console.log({ location })
    }

  }, [selectedId])
  // const [selected, setSelected] = React.useState(null);


  const onMapClick = React.useCallback((e) => {

    setMarkers(() => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);


  const addLocationHandler = () => {
    const token = localStorage.getItem('token');

    let id = document.getElementById('mDLocDevId');
    let lat = document.getElementById('mDLocLat');
    let lng = document.getElementById('mDLocLng');

    let url = `${process.env.REACT_APP_BASE_URL}/devices/updateDeviceLocation/${id.value}`;

    let addDeviceLocation = {
      "location": [lat.value, lng.value]
    }
    console.log(addDeviceLocation)
    console.log(token)

    axios.patch(url, addDeviceLocation, {
      headers: {
        'x-api-key': token
      }
    })
      .then(res => {
        console.log({ res })
        const { data } = res;
        console.log(data.deviceDetails);
        let deviceInfo = JSON.parse(localStorage.getItem('devices'));
        console.log(deviceInfo);
        let deviceInfoDevices = deviceInfo.devices.map(dev => {
          if (dev.deviceDetails.device_id === id.value) {
            dev.deviceDetails = data.deviceDetails
          }
          return dev;
        }
        );
        deviceInfo.devices = deviceInfoDevices;
        console.log(deviceInfo);
        localStorage.setItem('devices', JSON.stringify(deviceInfo));
        alert("Device Location Updated Successfully!")
      })
      .catch(err => {
        console.log({ err })
      })




  }

  if (loadError) return "Error occured while loading map";
  if (!isLoaded) return "Loading Google Maps";

  const latitudeChangeHandler = (e) => {
    let lat = +e.target.value;

    if (lat === 0) {

      setMarkers((prev) => [
        {
          ...prev[0],
          lat: '', // or handle it differently based on your use case
        },
      ]);
    } else {
      // Validate if the input is a valid number
      if (/^[-+]?\d*\.?\d*$/.test(lat)) {
        setMarkers((prev) => [
          {
            ...prev[0],
            lat: +(lat),
          },
        ]);
      }
    }
  }

  const longitudeChangeHandler = (e) => {
    let lng = e.target.value;
    console.log({ lng })

    // Validate if the input is a valid number or empty string
    if (/^[-+]?\d*\.?\d*$/.test(lng) || lng === "") {
      setMarkers((prev) => [
        {
          ...prev[0],
          lng: parseFloat(lng),
        },
      ]);
    }
  };

  return (
    <>
      <Locate panTo={panTo} />
      <Search panTo={panTo} />

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        // google={this.props.google}
        zoom={10}
        // style={{ mapStyles }}
        center={center}
        onClick={onMapClick}
        options={options}
        onLoad={onMapLoad}
      >
        {markers[0] && <Marker position={{ lat: markers[0].lat, lng: markers[0].lng }} />}
      </GoogleMap>

      {markers[0] &&
        // <div className="row">
        <div className="" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", flexBasis: "300px", justifyContent: "center", alignItems: "center" }}>

          <div className="mdDelDev" theme={props.dataColor} style={{ minWidth: "100px", width: "100%" }}>
            <select
              id="mDLocDevId"
              onChange={(e) => setSelectedId(e.target.value)}
            >
              {props.ids.map(item => {
                return (<option value={item.id} key={`id_${item.id}`}>{item.id}</option>)
              })}

            </select>
          </div>

          <TextBox
            id="mDLocLat"
            theme={props.dataColor}
            value={markers[0].lat}
            change={latitudeChangeHandler}
            placeholder="Latitude"
            type="number"

            style={{ minWidth: "200px", width: "100%" }}
          ></TextBox>
          <TextBox
            id="mDLocLng"
            theme={props.dataColor}
            value={markers[0].lng}
            change={longitudeChangeHandler}
            placeholder="Longitude"
            type="number"
            style={{ minWidth: "200px", width: "100%" }}
          ></TextBox>

          <NeuButton
            theme={props.dataColor}
            // Text="Add"
            _style="main"
            icon="fad fa-plus-circle"
            onClick={addLocationHandler}
          >
            Add
          </NeuButton>

        </div>
        // <div className="col-md-4">


        //   <div
        //     style={{
        //       marginTop: "20px",
        //       textAlign: "right",
        //       width: "100%"
        //     }}
        //   >

        //   </div>
        // </div>


      }
    </>
  );

}

export default MapGetLocation;

function Locate({ panTo }) {
  return (

    <button
      className="getCurrentLocation"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <i className="fad fa-location"></i>
    </button>
  );
}

function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
    },
  });

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    console.log(e.target.value)
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      console.log(results[0])
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      console.log({ lat, lng })
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };



  return (
    <div className="map-search">

      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="🔎 Search your location" /
        >

        <ComboboxPopover style={{ zIndex: "30", backgroundColor: "#e6effe" }}>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id + description} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

