import React, { Component } from "react";
// import sidebar_img from "./sidebar-5.jpg";
import "../../assets/css/demo.css";
import ene_logo from "../../assets/img/logoWhite.svg";
import { NavLink } from "react-router-dom";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";


class Sidebar extends Component {

  render() {
    return (
      <div
        className="sidebar"
        // data-color={this.props.dataColor}
        theme={this.props.theme}
        id="sidebar"

      // data-image={sidebar_img}
      // onLoad={props.navigation}
      >
        {/* Tip 1: you can change the color of the sidebar using: data-color="blue | azure | green | orange | red | purple"
    Tip 2: you can also add an image using data-image tag */}
        <div className="sidebar-wrapper">
          <div className="logo">
            <a href="www.enggenv.com" onClick={this.props.toggleNavigation} className="simple-text">
              <img id="ene_logo" src={ene_logo} alt="" style={{ width: "200px" }} />
            </a>
          </div>
          <ul className="nav">
            <li id="sb_1">
              <NavLink exact to="/" activeClassName="active" onClick={this.props.toggleNavigation}>
                <i className="fad fa-chart-network"><span className="notif-badge"></span></i>
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li id="sb_2">
              <NavLink to="/Profile" activeClassName="active" onClick={this.props.toggleNavigation}>
                <i className="fad fa-users-cog notif">
                  <span className="notif-badge"></span>
                </i>
                <p>User Profile</p>
              </NavLink>
            </li>
            <li id="sb_5">
              <NavLink to="/ManageDevices" activeClassName="active">
                <i className="fad fa-abacus"><span className="notif-badge"></span></i>
                <p>Manage Devices</p>
              </NavLink>
            </li>
            <li id="sb_3">
              <NavLink to="/Settings" activeClassName="active">
                <i className="fad fa-cog"><span className="notif-badge"></span></i>
                <p>Settings</p>
              </NavLink>
            </li>
            <li id="sb_4">
              <NavLink to="/Reports" activeClassName="active">
                <i className="fad fa-file-chart-pie"><span className="notif-badge"></span></i>
                <p>Reports</p>
              </NavLink>
            </li>
            {/* <li>
            <a href="typography.html">
              <i className="pe-7s-news-paper"></i>
              <p>Typography</p>
            </a>
          </li>
          <li>
            <a href="icons.html">
              <i className="pe-7s-science"></i>
              <p>Icons</p>
            </a>
          </li>
          <li>
            <a href="maps.html">
              <i className="pe-7s-map-marker"></i>
              <p>Maps</p>
            </a>
          </li> */}
            {/* <li id="sb_4">
              <NavLink to="/Alerts" activeClassName="active">
                <i className="fad fa-bells"><span className="notif-badge"></span></i>
                <p>Alerts</p>
              </NavLink>
            </li> */}

            <li id="sb_5" onClick={this.props.revokeAuthentication}>
              <NavLink to="/" activeClassName="deactive">
                {/* <a> */}
                <i className="fad fa-sign-out-alt"></i>
                <p>Logout</p>
                {/* </a> */}
              </NavLink>
            </li>
            {/* <li className="active-pro">
            <a href="upgrade.html">
              <i className="pe-7s-rocket"></i>
              <p>Upgrade to PRO</p>
            </a>
          </li> */}
          </ul>
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = dispatch => {
  return { revokeAuthentication: () => dispatch(actions.checkAuthTimeout(10)) }
}

export default connect(null, mapDispatchToProps)(Sidebar);
