import React, { Component } from "react";
import "./ForcasteCard.scss";
import axios from "axios";
// import { DEGREES } from "@amcharts/amcharts4/.internal/core/utils/Math";

// let api_key = "030b2a47ce111467a7f99040d6013055";


class forcaste extends Component {



  state = {
    forecaste: {
      all: {
        day1: {
          name: "Day-1",
          main: { temp: [], humidity: [] },
          weather: { des: [] },
          wind: { speed: [], direction: [] },
          dt_txt: [],
        },
        day2: {
          name: "Day-2",
          main: { temp: [], humidity: [] },
          weather: { des: [] },
          wind: { speed: [], direction: [] },
          dt_txt: [],
        },
        day3: {
          name: "Day-3",
          main: { temp: [], humidity: [] },
          weather: { des: [] },
          wind: { speed: [], direction: [] },
          dt_txt: [],
        },
        day4: {
          name: "Day-4",
          main: { temp: [], humidity: [] },
          weather: { des: [] },
          wind: { speed: [], direction: [] },
          dt_txt: [],
        },
        day5: {
          name: "Day-5",
          main: { temp: [], humidity: [] },
          weather: { des: [] },
          wind: { speed: [], direction: [] },
          dt_txt: [],
        },
        location: [],
      },
      error: {
        isavailable: false,
        msg: "",
      },
    },
    default_day: {},
    error: null,
    isLoaded: false,
    items: {}
  };

  _promise = false;
  updateDefaultDay() {
    this.setState({
      default_day: this.state.forecaste.all.day1,
    });
  }

  componentDidMount() {


    let deviceList = JSON.parse(localStorage.getItem('devices')).devices;
    console.log({ deviceList })
    console.log(this.props.selectedId)

    let deviceDetails = (deviceList.filter((item) => item.deviceDetails.device_id === this.props.selectedId));

    console.log({ deviceDetails })
    if (!deviceDetails?.[0]?.deviceDetails?.location) return;
    const [lat, long] = deviceDetails?.[0]?.deviceDetails?.location;
    console.log({ lat, long })
    this.updateDefaultDay();

    const forecaste_api = () => {
      axios
        .get(
          `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${long}&appid=030b2a47ce111467a7f99040d6013055`
        )
        .then(
          (res) => {

            this._promise = true;
            //const posts = res.data.map(obj => obj.data);
            // const city=res.data.city.name;

            this.setState({
              forecaste: {
                all: {
                  day1: {
                    name: "Day-1",
                    main: {
                      temp: [res.data.list[10].main.temp],
                      humidity: [res.data.list[10].main.humidity],
                    },
                    weather: { des: res.data.list[10].weather[0].description },
                    wind: {
                      speed: res.data.list[10].wind.speed,
                      direction: res.data.list[10].wind.deg,
                    },
                    dt_txt: res.data.list[10].dt_txt,
                  },
                  day2: {
                    name: "Day-2",
                    main: {
                      temp: [res.data.list[18].main.temp],
                      humidity: [res.data.list[18].main.humidity],
                    },
                    weather: { des: res.data.list[18].weather[0].description },
                    wind: {
                      speed: res.data.list[18].wind.speed,
                      direction: res.data.list[18].wind.deg,
                    },
                    dt_txt: res.data.list[18].dt_txt,
                  },
                  day3: {
                    name: "Day-3",
                    main: {
                      temp: [res.data.list[24].main.temp],
                      humidity: [res.data.list[24].main.humidity],
                    },
                    weather: { des: res.data.list[24].weather[0].description },
                    wind: {
                      speed: res.data.list[24].wind.speed,
                      direction: res.data.list[24].wind.deg,
                    },
                    dt_txt: res.data.list[24].dt_txt,
                  },
                  day4: {
                    name: "Day-4",
                    main: {
                      temp: [res.data.list[30].main.temp],
                      humidity: [res.data.list[30].main.humidity],
                    },
                    weather: { des: res.data.list[30].weather[0].description },
                    wind: {
                      speed: res.data.list[30].wind.speed,
                      direction: res.data.list[30].wind.deg,
                    },
                    dt_txt: res.data.list[30].dt_txt,
                  },
                  day5: {
                    name: "Day-5",
                    main: {
                      temp: [res.data.list[36].main.temp],
                      humidity: [res.data.list[36].main.humidity],
                    },
                    weather: { des: res.data.list[36].weather[0].description },
                    wind: {
                      speed: res.data.list[36].wind.speed,
                      direction: res.data.list[36].wind.deg,
                    },
                    dt_txt: res.data.list[36].dt_txt,
                  },
                  location: res.data.city.name,
                },
              },
              default_day: {
                name: "Day-1",
                main: {
                  temp: [res.data.list[10].main.temp],
                  humidity: [res.data.list[10].main.humidity],
                },
                weather: { des: res.data.list[10].weather[0].description },
                wind: {
                  speed: res.data.list[10].wind.speed,
                  direction: res.data.list[10].wind.deg,
                },
                dt_txt: res.data.list[10].dt_txt,
              },
            });


          },
          (error) => {
            this._promise = false;
            this.setState({ forecaste: { error: { msg: error } } });
            // alert(this.state.forecaste.error.msg);
          }
        );
    };

    if (!navigator.onLine) {
      const forecast_loop = setInterval(() => {
        forecaste_api();
        if (navigator.onLine) {
          // alert("forecaste_loop");
          clearInterval(forecast_loop);
        }
      }, 3000);
    } else {
      this._promise = true;
      forecaste_api();
    }
  }




  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     error: null,
  //     isLoaded: false,
  //     items: {}
  //   };
  // }

  foreCount = 1;
  render() {


    const forecasteNextHandler = (prop) => {
      // alert(this.foreCount);
      console.log(this.state)

      if (prop === "plus") {
        if (this.foreCount < 5) {
          this.foreCount++;
        }
      } else if (prop === "minus") {
        if (this.foreCount > 1) {
          this.foreCount--;
        }
      }
      switch (this.foreCount) {
        case 1:
          // //alert("case1: " + this.foreCount);
          this.setState({
            default_day: this.state.forecaste.all.day1,
          });
          break;

        case 2:
          // //alert("case2: " + this.foreCount);
          this.setState({
            default_day: this.state.forecaste.all.day2,
          });
          break;

        case 3:
          // //alert(this.foreCount);
          this.setState({
            default_day: this.state.forecaste.all.day3,
          });

          break;

        case 4:
          this.setState({
            default_day: this.state.forecaste.all.day4,
          });

          break;

        case 5: {
          this.setState({
            default_day: this.state.forecaste.all.day5,
          });
        }
          break;

        default: {

        }
      }
    };

    return (

      <div className="forecast_card" style={{}}>
        {this._promise ?
          <>
            <div
              className="btnPrev"
              isavailable={this.props.isavailable}
              onClick={forecasteNextHandler.bind(this, "minus")}
            >
              <i className="icon fad fa-chevron-circle-left"></i>
            </div>
            <div
              className="btnNext"
              isavailable={this.props.isavailable}
              onClick={forecasteNextHandler.bind(this, "plus")}
            >
              <i className="icon fad fa-chevron-circle-right"></i>
            </div>

            <div className="f_header">
              <h5>5 Days Forecaste {this.state.default_day.name}</h5>
            </div>
            <div className="f_content">
              <h5>
                {" "}
                <i
                  className="icon fad fa-calendar-alt"
                  style={{ color: "#4C8660" }}
                ></i>{" "}
                {this.state.default_day.dt_txt != 0 &&
                  this.state.default_day.dt_txt.substr(0, 10)
                }
                {"   "}
                <i
                  className="icon fad fa-map-marker-alt "
                  style={{ color: "#DB4160", marginLeft: "10px" }}
                ></i>{" "}
                {this.state.forecaste.all.location}
              </h5>
              <hr />
              <span className="temp">
                <i
                  className="icon-l  fad fa-temperature-high "
                  style={{ color: "#CE723B" }}
                ></i>{" "}
                {(this.state.default_day.main.temp - 273.15).toFixed(1)}{" "}
                <small className="units">℃</small>
                {/* <span className="tooltiptext">Temperature</span> */}
              </span>

              {/* <span>
                <small>{this.state.default_day.weather.des}</small>
              </span> */}


              < span className="hum">
                <i
                  className="icon  fad fa-humidity icon"
                  style={{ color: "#B178BF" }}
                ></i>{" "}
                {this.state.default_day.main.humidity}{" "}
                <small className="units">%</small>
              </span>
              <br />
              <span>
                <i className="icon fad fa-clouds-sun"></i> <small>{this.state.default_day.weather.des}</small>
              </span>
              <i
                className="icon fad fa-wind"
                style={{ color: "#77A4DD", fontSize: "25px" }}
              ></i>
              <span>
                {" "}
                <small>
                  {this.state.default_day.wind.speed} km/h in {this.state.default_day.wind.direction}{" "}
                  {(this.state.default_day.wind.direction === 0 && "ºN") ||
                    (this.state.default_day.wind.direction > 0 &&
                      this.state.default_day.wind.direction < 90 &&
                      "ºNE") ||
                    (this.state.default_day.wind.direction === 90 && "ºE") ||
                    (this.state.default_day.wind.direction > 90 &&
                      this.state.default_day.wind.direction < 180 &&
                      "ºSE") ||
                    (this.state.default_day.wind.direction === 180 && "ºS") ||
                    (this.state.default_day.wind.direction > 180 &&
                      this.state.default_day.wind.direction < 270 &&
                      "ºSW") ||
                    (this.state.default_day.wind.direction === 270 && "ºW") ||
                    (this.state.default_day.wind.direction > 270 &&
                      this.state.default_day.wind.direction < 360 &&
                      "ºNW")}
                </small>
              </span>
            </div>
          </>
          : <div style={{ padding: 20 }}>

            <h1 style={{ fontSize: 16 }}>Forcaste is not available right now, please try login again to view forcaste.</h1>
          </div>
        }
      </div>

    );

  }
}

export default forcaste;


// isavailable = "true"
// nextBtn = { forecasteNextHandler }
// day = { this.state.default_day.name }
// temp = {
//   (this.cookies.get("temp_u") === "deg_c" &&
//   (this.state.default_day.main.temp - 273.15).toFixed(1)) ||
//   (this.cookies.get("temp_u") === "deg_f" &&
//     (
//       (this.state.default_day.main.temp - 273.15) * (9 / 5) +
//       32
//     ).toFixed(1)) ||
//   (this.cookies.get("temp_u") === "deg_k" &&
//     (this.state.default_day.main.temp * 1).toFixed(1))
// }
// unit = {
//   (this.cookies.get("temp_u") === "deg_c" && "℃") ||
//   (this.cookies.get("temp_u") === "deg_f" && "℉") ||
//   (this.cookies.get("temp_u") === "deg_k" && "K")
// }
// location = { this.state.forecaste.all.location }
// date = {
//   this.state.default_day.dt_txt != 0 &&
//     this.state.default_day.dt_txt.substr(0, 10)
// }
// humidity = { this.state.default_day.main.humidity }
// weather = { this.state.default_day.weather.des }
// windSpeed = { this.state.default_day.wind.speed }
// windDirection = { this.state.default_day.wind.direction }