import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import CustomiseCard from "../../Components/Customise/Customise";
import "../../Components/RadioCon/radio_con.scss";
import axios from "axios";

function Settings(props) {
  const cookies = new Cookies();

  // State initialization using useState
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [layout, setLayout] = useState(getInitialLayout());
  const [allPara, setAllPara] = useState([]);
  const [selectedPara, setSelectedPara] = useState([]);

  // Equivalent to componentDidMount and componentDidUpdate:
  useEffect(() => {
    getDefaultTheme();

    let devices = JSON.parse(localStorage.getItem("devices"))?.devices;
    let __types = devices.reduce((acc, device) => {
      let type = extractAlphabets(device.deviceDetails.device_id);
      if (!acc.includes(type)) {
        acc = [...acc, type];
      }
      return acc;
    }, []);

    setTypes(__types);
    typeChangeHandler(__types[0]);

    const isMobileView = window.matchMedia("(max-width: 768px)").matches;

    if (isMobileView) {
      // Code for mobile view
      document.getElementById("sidebar").style = "width:0";
    }
  }, []); // Empty dependency array means this effect runs once on mount

  function getInitialLayout() {
    if (cookies.get("layout") === "card") {
      return [{ card: "unit current" }, { table: "unit" }];
    } else if (cookies.get("layout") === "table") {
      return [{ card: "unit" }, { table: "unit current" }];
    } else {
      return [{ card: "unit current" }, { table: "unit" }];
    }
  }

  function extractAlphabets(inputString) {
    // Use a regular expression to match only alphabets
    const alphabetsOnly = inputString.replace(/[^a-zA-Z]/g, "");
    return alphabetsOnly;
  }

  function getDefaultTheme() {
    const themeIcon = document.getElementById("theme-icon");
    if (themeIcon) {
      var theme = document.documentElement.getAttribute("theme-data");
      if (theme === "Light") {
        themeIcon.classList.replace("fa-sun", "fa-moon-stars");
        themeIcon.style.color = "#00C0FF";
      } else {
        themeIcon.classList.replace("fa-moon-stars", "fa-sun");
        themeIcon.style.color = "#FFCA28";
      }
    }
  }

  function themeUpdateHandler() {
    var theme = document.documentElement.getAttribute("theme-data");
    if (theme !== "Light") {
      document.documentElement.setAttribute("theme-data", "Light");
      localStorage.setItem("theme-data", "Light");
    } else {
      document.documentElement.setAttribute("theme-data", "Dark");
      localStorage.setItem("theme-data", "Dark");
    }
    getDefaultTheme();
  }

  function accentColorHandler(color, pallet) {
    document.documentElement.setAttribute("accent-color", color);
    localStorage.setItem("accent-color", color);
  }

  function radiohandler(val) {
    if (val === "card" || val === "table") {
      const newLayout = layout.map((item) => {
        const key = Object.keys(item)[0];
        return { [key]: key === val ? "unit current" : "unit" };
      });
      setLayout(newLayout);
      cookies.set("layout", val, { path: "/" });
    }
  }

  const typeChangeHandler = (type) => {
    let token = localStorage.getItem("token");
    let URL = process.env.REACT_APP_BASE_URL;
    console.log({ token, type, URL });

    setSelectedType(type);

    let prevParaAsPerType =
      JSON.parse(localStorage.getItem("visibleParaForType")) || {};
    setSelectedPara(prevParaAsPerType[type] || []);

    axios
      .get(`${URL}/devices/parameters/${type}`, {
        headers: {
          "x-api-key": token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setAllPara(res?.data?.parameters);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addSelectedParaHandler = (para) => {
    // alert(1);
    if (!selectedPara.includes(para)) {
      let __prevpara = [...selectedPara];
      __prevpara.push(para.toLowerCase());
      setSelectedPara((prevPara) => [...prevPara, para.toLowerCase()]);
      console.log({ __prevpara });
      let prevParaAsPerType =
        JSON.parse(localStorage.getItem("visibleParaForType")) || {};
      prevParaAsPerType = {
        ...prevParaAsPerType,
        [selectedType]: __prevpara,
      };
      localStorage.setItem(
        "visibleParaForType",
        JSON.stringify(prevParaAsPerType)
      );

      let token = localStorage.getItem("token");
      let URL = process.env.REACT_APP_BASE_URL;
      console.log({ token });
      axios
        .put(
          `${URL}/users/update-config`,
          {
            ...prevParaAsPerType,
          },
          {
            headers: {
              "x-api-key": token,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          alert(res?.data?.msg);
          // setAllPara(res?.data?.parameters);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const removeSelectedParaHandler = (para) => {
    let __prevpara = selectedPara;
    __prevpara = __prevpara.filter((prevPara) => prevPara !== para);
    setSelectedPara(__prevpara);

    console.log(__prevpara);
    let prevParaAsPerType =
      JSON.parse(localStorage.getItem("visibleParaForType")) || {};
    prevParaAsPerType = {
      ...prevParaAsPerType,
      [selectedType]: __prevpara,
    };
    localStorage.setItem(
      "visibleParaForType",
      JSON.stringify(prevParaAsPerType)
    );
    let token = localStorage.getItem("token");
    let URL = process.env.REACT_APP_BASE_URL;
    console.log({ token });
    axios
      .put(
        `${URL}/users/update-config`,
        {
          ...prevParaAsPerType,
        },
        {
          headers: {
            "x-api-key": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert(res?.data?.msg);
        // setAllPara(res?.data?.parameters);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const saveSelectedPara = () => {};

  const Row = (props) => {
    return (
      <div className="row settings_row" data-color={props.themename}>
        <div className="col-md-6 text-left">
          {" "}
          <h5 className="cus_data" head-color={props.textColor}>
            {props.setType}
          </h5>
        </div>
        {/* {alert(_icon())}  */}
        <div className="col-md-6 text-center">
          {" "}
          <div className="inline">{props.children}</div>
          {/* <span>{this.props.dataValue}</span> */}
        </div>
      </div>
    );
  };

  // Render function equivalent
  return (
    <div className="content" style={{ margin: "30px" }}>
      <h4 className="pageTitle" color="light">
        Settings
      </h4>
      <div className="row">
        <div className="col-md-4">
          <CustomiseCard
            header="Customise Dashboard"
            dataColor={props.dataColor}
            sidebarColor={props.sidebar}
            themename={props.themename}
          >
            <Row
              setType="Theme"
              themeColor={props.theme}
              textColor={props.textColor}
              themename={props.themename}
            >
              <button
                className="switchBtn"
                data-color={props.textColor}
                onClick={themeUpdateHandler}
              >
                {" "}
                <span>{props.themename}</span> {"  "}
                <i
                  id="theme-icon"
                  style={{ fontSize: "2.5rem" }}
                  className="fad fa-moon-stars"
                ></i>{" "}
                {"  "}
                <span>{props.dataValue}</span>
              </button>
            </Row>
            <Row
              setType="Accent Color"
              themeColor={props.theme}
              textColor={props.textColor}
              themename={props.themename}
            >
              <div
                className="color-pallet"
                id="pa1"
                data-color="Red-Light"
                onClick={accentColorHandler.bind(this, "red", "pa1")}
              ></div>
              <div
                className="color-pallet"
                id="pa2"
                data-color="Blue-Light"
                onClick={accentColorHandler.bind(this, "blue", "pa2")}
              ></div>
              <div
                className="color-pallet"
                id="pa3"
                data-color="Green-Light"
                onClick={accentColorHandler.bind(this, "green", "pa3")}
              ></div>
              <div
                className="color-pallet"
                id="pa4"
                data-color="Purple-Light"
                onClick={accentColorHandler.bind(this, "purple", "pa4")}
              ></div>

              <div
                className="color-pallet"
                id="pa6"
                data-color="Orange-Light"
                onClick={accentColorHandler.bind(this, "orange", "pa6")}
              ></div>
              <div
                className="color-pallet"
                id="pa7"
                data-color="Yellow-Light"
                onClick={accentColorHandler.bind(this, "yellow", "pa7")}
              ></div>
              <div
                className="color-pallet"
                id="pa8"
                data-color="Grey-Light"
                onClick={accentColorHandler.bind(this, "grey", "pa8")}
              ></div>
              <br />
            </Row>
          </CustomiseCard>
        </div>

        <div className="col-md-4">
          <CustomiseCard
            header="Dashboard Layout"
            dataColor={props.dataColor}
            sidebarColor={props.sidebar}
            themename={props.themename}
          >
            <Row
              setType="Layout"
              themeColor={props.theme}
              textColor={props.textColor}
              themename={props.themename}
            >
              <div className="radio_con_units" style={{ width: "90%" }}>
                <div
                  className={layout[0].card}
                  onClick={radiohandler.bind(this, "card")}
                >
                  Cards
                </div>
                <div
                  className={layout[1].table}
                  onClick={radiohandler.bind(this, "table")}
                >
                  Table
                </div>
                <div className="slider"></div>
              </div>
            </Row>
          </CustomiseCard>
        </div>

        <div className="col-md-4">
          <CustomiseCard
            header="Hide Parameters"
            dataColor={props.dataColor}
            sidebarColor={props.sidebar}
            themename={props.themename}
          >
            <Row
              setType="Select Device"
              themeColor={props.theme}
              textColor={props.textColor}
              themename={props.themename}
            >
              <select
                onChange={(e) => typeChangeHandler(e.target.value)}
                style={{
                  backgroundColor: "#0072bc",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  padding: "3px 8px",
                  outline: "none",
                  border: "none",
                }}
              >
                {types.map((type) => {
                  return <option key={type}>{type}</option>;
                })}
              </select>
            </Row>
            <h6 style={{ paddingLeft: "2rem" }}>{selectedType}</h6>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
                padding: "2rem",
              }}
            >
              {Object.keys(allPara)?.map((para) => {
                return (
                  <button
                    style={{
                      backgroundColor: "#0072bc",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      padding: "5px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => addSelectedParaHandler(para)}
                    key={`AllPara_${para}`}
                  >
                    {para.split("_").join(" ")}
                  </button>
                );
              })}
            </div>
            <h6 style={{ textTransform: "capitalize", paddingLeft: "2rem" }}>
              These Parameters are visible on the dashboard & reports
            </h6>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
                padding: "2rem",
              }}
            >
              {selectedPara.map((para) => {
                return (
                  <div
                    key={`SelectedPara_${para}`}
                    style={{
                      display: "flex",
                      backgroundColor: "#0072bc",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      padding: "5px",
                      gap: "1rem",
                    }}
                  >
                    <span
                      style={{
                        textTransform: "capitalize",
                        padding: "0 1rem",
                        borderRight: "1px solid #eee",
                      }}
                      onClick={() => addSelectedParaHandler(para)}
                    >
                      {para.split("_").join(" ")}
                    </span>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "0 0.5rem",
                      }}
                      onClick={() => removeSelectedParaHandler(para)}
                    >
                      x
                    </button>
                  </div>
                );
              })}
            </div>
          </CustomiseCard>
        </div>
      </div>
    </div>
  );
}

export default Settings;

// Note: Ensure to replace or handle `extractAlphabets` function and any other methods
//       or variables used in the original class component but not defined here.
