import React, { Component } from 'react';
import DateBox from '../Datebox/Datebox';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";

am4core.useTheme(am4themes_animated);
var dateChangeManually = false, daysDifferenceInChart = 5;


class LineChart extends Component {
    token = localStorage.getItem('token');

    state = {
        current_para: "",
        all_data: {},
        timestamps: {},
        lut: "",
        startDate: "nan",
        endDate: "nan",
    };

    componentDidMount() {
        // ##################################### line chart date select event listener ################################
        document.getElementById("chartFrom").addEventListener('change', function () {
            this.setState({ startDate: document.getElementById("chartFrom").value.toString() });
            dateChangeManually = true;
            am4core.disposeAllCharts();
            this.generateChart();
        }.bind(this));
        document.getElementById("chartTo").addEventListener('change', function () {
            this.setState({ endDate: document.getElementById("chartTo").value.toString() });
            dateChangeManually = true;
            am4core.disposeAllCharts();
            this.generateChart();
        }.bind(this));



        this.generateChart();
        this.chartReconnect();
    }

    // shouldComponentUpdate() { return false }

    getChartValue() {
        var result;
        return new Promise((resolve) => {
            setTimeout(() => {
                axios
                    .get(
                        `${process.env.REACT_APP_BASE_URL}/devices/getLatestDeviceData/${this.props.selectedId}`, {
                        headers: {
                            'x-api-key': this.token
                        }
                    })
                    .then((res) => {
                        if (!dateChangeManually) {
                            console.log({ result: res.data })
                            result = res.data.recorded_time;
                            result = result.split(', ');         //result eg. 4/20/2021, 12:00:00 
                            let date = result[0].split('/');
                            let time = result[1].split(':');
                            date[0]--;                           //in date obj month starts from 0-11,  
                            let finalLut = new Date();

                            finalLut.setFullYear(date[2], date[0], date[1]);
                            finalLut.setHours(time[0]);
                            finalLut.setMinutes(time[1]);
                            var day = ("0" + finalLut.getDate()).slice(-2);
                            var month = ("0" + (finalLut.getMonth() + 1)).slice(-2);
                            let dateFormated = `${finalLut.getFullYear()}-${month}-${day}`;
                            document.getElementById("chartTo").value = dateFormated;
                            this.setState({ endDate: dateFormated });
                            let startDate = new Date();
                            // console.log(finalLut.getMonth() + 1, finalLut.getDate() - daysDifferenceInChart, finalLut.getYear() + 1900);

                            startDate.setFullYear(finalLut.getYear() + 1900, finalLut.getMonth(), finalLut.getDate() - daysDifferenceInChart);
                            // alert(startDate);

                            let day1 = ("0" + startDate.getDate()).slice(-2);
                            let month1 = ("0" + (startDate.getMonth() + 1)).slice(-2);
                            let startDateFormated = `${startDate.getFullYear()}-${month1}-${day1}`;
                            document.getElementById("chartFrom").value = startDateFormated;
                            this.setState({ startDate: startDateFormated });

                        }
                        axios
                            .get(
                                `${process.env.REACT_APP_BASE_URL}/devices/getDataByDate/${this.props.selectedId}/${this.state.startDate}/${this.state.endDate}/default`, {
                                headers: {
                                    'x-api-key': this.props.token
                                }
                            }
                            )
                            .then((res) => {
                                resolve(res);
                            })
                            .catch((err) => {
                                if (err.toString().includes("400")) {
                                    if (isNaN(this.state.startDate)) {
                                        window.location.reload();
                                    }
                                    else {
                                        alert("No data Available in between these Dates");
                                    }
                                }
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    })

            }, 200);
        }).then((dataObject) => {
            console.log({ data: dataObject.data })
            this.setState({ timestamps: dataObject.data.recorded_time });
            // delete dataObject.data.recorded_time;
            // delete dataObject.data.recieved_time;
            this.setState({ all_data: dataObject.data })
            console.log(this.state.all_data);
            this._para = Object.keys(this.state.all_data)[this.paraStep];
        });
    }

    async generateChart() {
        try {
            await this.getChartValue();
            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("chartdiv", am4charts.XYChart);
            chart.paddingRight = 20;
            let data = [];
            let all_data = [], all_time = new Date();
            chart.data = [];
            all_time.setDate(all_time.getDate());
            am4core.options.autoDispose = true;
            console.time();
            console.log('All Data ', 'background: #222; color: #bada55');
            all_data = this.state.all_data[this._para];
            all_time = this.state.timestamps;
            console.timeEnd();
            console.log({ all_data, all_time });

            // let j = 0;
            for (let i = all_data.length - 1; i > 1; i--) {
                console.log(all_time[i]);
                const [datePart, timePart] = all_time[i].split(", ");
                const [day, month, year] = datePart.split("/").map(Number);
                const [hours, minutes] = timePart.split(":").map(Number);

                // Create a new Date object
                const newDate = new Date(year, month - 1, day, hours, minutes);
                // var newDate = new Date()
                if (all_data[i] !== "-999") {
                    data.push({
                        date: newDate,
                        name: this.state.current_para,
                        value: all_data[i],
                    });
                }
            }
            chart.data = data;
            chart.dateFormatter.dateFormat = "dd-MM-yyyy, HH:mm:ss";
            let dateAxis = {};
            dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.grid.template.location = 0;
            chart.legend = new am4charts.Legend();
            dateAxis.baseInterval = {
                timeUnit: "minute",
                count: 1,
            };
            let valueAxis = {};
            valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            if (!all_data.some(item => item < 0)) {
                valueAxis.min = 0;   // without specifying this chart scales are set automatically, and shows spikes which inaccurately indicate noise in data.
            }
            valueAxis.tooltip.disabled = true;
            valueAxis.renderer.minWidth = 35;
            let series = chart.series.push(this._para.includes('rain') ? new am4charts.ColumnSeries() : new am4charts.LineSeries());
            series.dataFields.dateX = "date";
            series.dataFields.valueY = "value";
            series.strokeWidth = 2;
            series.minBulletDistance = 15;
            series.name = this._para.toUpperCase();
            series.tooltipText =
                this._para.toUpperCase() +
                `\n---------------------\n ` +
                `[bold]Value:  ` +
                "  {valueY.value}\n[bold]Date: {dateX}";
            chart.cursor = new am4charts.XYCursor();
            chart.seriesContainer.draggable = false;
            chart.seriesContainer.resizable = false;
            // series.fill = am4core.color("#4B53BC");
            // series.fillOpacity = 0.1;
            // series.tensionX = 0.8;
            // let scrollbarX = new am4charts.XYChartScrollbar();
            // scrollbarX.series.push(series);
            // chart.scrollbarX = scrollbarX;
            this.chart = chart;
            chart.events.on("ready", function (ev) {
                valueAxis.min = valueAxis.minZoomed;
                valueAxis.max = valueAxis.maxZoomed;
            });
        } catch (err) {
            console.log(err)
        }
    }

    chartReconnect() {
        // ################################# charts get reloaded when internet reconnects ###############################
        if (!navigator.onLine) {
            const chart_loop = setInterval(() => {
                if (navigator.onLine) {
                    am4core.disposeAllCharts();
                    this.generateChart();
                    clearInterval(chart_loop);
                }
            }, 1000);
        }
    }

    // ##################################### This function returns the size of the object #################################
    objSize = (obj) => {
        var size = 0;
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    chartRotationToggle() {
        var checkBox = document.getElementById("chartRotationSwitch");
        if (!checkBox.checked) {
            this._chartRotation = 0;
        } else {
            this._chartRotation = 1;
            this.chartAutoRotateHandler();
        }
    }

    chartAutoRotateHandler = () => {
        let dataSize = this.objSize(this.state.all_data);
        this._para = Object.keys(this.state.all_data)[this.paraStep];
        if (this._chartRotation && this.paraStep < dataSize) {
            this.paraStep++;
            this.paraStep = this.paraStep >= dataSize ? 0 : this.paraStep;
            am4core.disposeAllCharts();
            this.generateChart();
            setTimeout(this.chartAutoRotateHandler, 20000);
        }
        console.log(`${dataSize}  ${this.paraStep}`);
    };

    paraStep = 0;

    chartNavigation = (command) => {
        let dataSize = this.objSize(this.state.all_data);

        if (command === "plus") {
            if (this.paraStep < dataSize) {
                this.paraStep++;
                this.paraStep = this.paraStep >= dataSize ? 0 : this.paraStep;
                // this._para = Object.keys(this.state.all_data)[this.paraStep++];
                am4core.disposeAllCharts();
                this.generateChart();
            }
            console.log(this.paraStep);
        } else if (command === "min") {
            if (this.paraStep >= 0) {
                this.paraStep--;
                this.paraStep = this.paraStep < 0 ? dataSize - 1 : this.paraStep;
                am4core.disposeAllCharts();
                this.generateChart();
            }
            console.log(this.paraStep);
        }
    };


    render() {

        return (
            <div>
                <div className="row bg-sec" style={{ minHeight: "50px" }}>
                    <div className="col-md-9 date">
                        <DateBox id="chartFrom" name="From" />
                        <DateBox id="chartTo" name="To" />
                    </div>
                    <div className="col-md-3">
                        <span style={{ display: "inline-block", color: "rgb(129, 148, 252)", padding: "20px 10px 10px 20px" }}>
                            Auto
                        </span>
                        <span
                            style={{
                                display: "inline-block",
                                paddingLeft: "10px",
                                position: "relative",
                                top: "5px",
                            }}
                        >
                            <label
                                className="mdl-switch mdl-js-switch mdl-js-ripple-effect"
                                htmlFor="chartRotationSwitch"
                                style={{
                                    display: "inline-block",
                                    float: "right",
                                    textAlign: "right",
                                }}
                            >
                                <input
                                    type="checkbox"
                                    id="chartRotationSwitch"
                                    className="mdl-switch__input"

                                    onClick={this.chartRotationToggle.bind(
                                        this,
                                        this._chartRotation
                                    )}
                                />
                                <span className="mdl-switch__label"></span>
                            </label>
                        </span>
                    </div>
                </div>

                <div
                    className="btnPrev"
                    isavailable="true"
                    onClick={this.chartNavigation.bind(this, "min")}
                >
                    <i className="icon fad fa-chevron-circle-left"></i>
                </div>
                <div
                    className="btnNext"
                    isavailable="true"
                    onClick={this.chartNavigation.bind(this, "plus")}
                >
                    <i className="icon fad fa-chevron-circle-right"></i>
                </div>

                <div
                    id="chartdiv"
                    className="chart-comp" style={{ margin: "20px" }}
                // data-color={this.props.dataColor}
                ></div>
            </div>
        );
    }
}

export default LineChart;